// Text
$accent-1: #0093ff;
$accent-2: #377dce;
$info: #b87ee5;
$invalid: #eb597b;

$link: #117dce;
$text-high-contrast: #304156;
$text-low-contrast: #828d99;
$text-white: #ffffff;

$bg-light: #fafbfb;
$bg-accent: #0376e3;
$bg-medium: #e7edf3;
$bg-grey: #f2f4f4;

$bg-side-1: #031b4d;
$bg-side-2: #283862;

$border-light: #f0f1f2;
$border-medium: #e1e5e8;

%uppercase {
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 0.1em;
	font-weight: 700;
}

%shadow {
	box-shadow: -7px 8px 16px 0 rgba(25, 42, 70, 0.13);
}
