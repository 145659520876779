@import '~reset-css/reset.css';
@import 'variables.scss';
@import '~@fortawesome/fontawesome-pro/css/all.css';

/**
 * NG SELECT
 */
@import '~@ng-select/ng-select/themes/default.theme.css';
.ng-select .ng-input {
	display: none;
}

html {
	font-size: 50%;
	height: 100%;
}

body {
	font-family: 'Archivo', sans-serif !important;
	font-weight: 500;
	font-size: 15px;
	height: 100%;
	background: $bg-light;
	color: $text-high-contrast;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5em;

	* {
		box-sizing: border-box;
	}
}

a {
	text-decoration: none;
}

button {
	border: none;
	padding: 0;
	background: none;
	outline: none;
	cursor: pointer;
}

h3 {
	@extend %uppercase;
	padding: 0;
	margin: 0 0 2rem 0;
	line-height: 2rem;
	color: $text-low-contrast;
}
hr {
	margin-bottom: 2rem;
	border: none;
	border-bottom: 1px solid $border-light;
}

h1 {
	padding: 0;
	margin: 0 0 2rem 0;
	line-height: 3rem;
	color: $text-low-contrast;
	font-size: 20px;
	font-weight: 500;
}

h2 {
	padding: 0;
	margin: 0 0 2rem 0;
	line-height: 3rem;
	color: $text-low-contrast;
	font-size: 18px;
	font-weight: 500;
}

.btn {
	display: inline-flex;
	height: 4.5rem;
	padding: 0 2rem;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	border-radius: 4px;
	font-size: 15px;
	&[disabled] {
		pointer-events: none;
		cursor: not-allowed;
	}

	&-block {
		width: 100%;
	}

	&-clear {
		background: transparent;
		color: $text-high-contrast;
		&:focus {
			background: $bg-light;
		}
		&:hover {
			background: $bg-light;
		}
		&:active {
			color: $text-low-contrast;
		}
	}

	&-primary {
		background: $accent-1;
		color: $text-white;
		transition: box-shadow 0.2s ease-out;
		&:focus {
			box-shadow: 0 0 0 3px lighten($accent-1, 40);
		}
		&:hover {
			background: lighten($accent-1, 15);
		}
		&:active {
			opacity: 0.75;
		}
		&[disabled] {
			background: $text-low-contrast;
			opacity: 0.5;
		}
	}

	&-secondary {
		background: $text-low-contrast;
		color: $text-white;
		transition: box-shadow 0.2s ease-out;
		&:focus {
			box-shadow: 0 0 0 3px lighten($text-low-contrast, 40);
		}
		&:hover {
			background: lighten($text-low-contrast, 15);
		}
		&:active {
			opacity: 0.75;
		}
		&[disabled] {
			background: $text-low-contrast;
			opacity: 0.5;
		}
	}
}

.container {
	padding: 4rem;
}

.container-h {
	padding: 0 4rem;
}

.margin-bottom {
	margin-bottom: 4rem;
}

.margin-top {
	margin-top: 4rem;
}

.margin-left {
	margin-left: 4rem;
}

.margin-right {
	margin-right: 4rem;
}

.padding-bottom {
	padding-bottom: 4rem;
}

.padding-top {
	padding-top: 4rem;
}

.padding-left {
	padding-left: 4rem;
}

.padding-right {
	padding-right: 4rem;
}

.aside-left {
	border-right: 1px solid $border-light;
}

.aside-right {
	border-left: 1px solid $border-light;
	min-height: calc(100vh - 180px);
	.aside-title {
		height: 4rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@extend %uppercase;
		background: $bg-grey;
		color: $text-low-contrast;
		padding: 0 4rem;
		// margin-top:
	}
	.aside-content {
		margin: 3rem 0;
		padding: 0 4rem;
		color: $text-low-contrast;

		.btn-block {
			margin-top: 3rem;
		}
	}
}

.form-field {
	position: relative;
	&--date-picker {
		button {
			position: absolute;
			right: 0;
			bottom: 0;
			height: 36px;
			width: 36px;
			display: flex;
			// background: $border-medium;
			border-radius: 4px;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			color: $text-high-contrast;
			&:active {
				opacity: 0.5;
			}
		}
	}
	&__title {
		color: $text-high-contrast;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		height: 3rem;
	}
}

input[type='text'],
input[type='password'] {
	border: 1px solid $border-medium;
	background: transparent;
	color: $text-high-contrast;
	height: 4.5rem;
	display: flex;
	padding: 0 1rem;
	font-size: 15px;
	border-radius: 4px;
	outline: none;
	width: 100%;
	transition: all 0.2s ease-out;
	&:focus {
		border-color: $accent-1;
		box-shadow: 0 0 0 3px lighten($accent-1, 40);
	}

	&.ng-touched:not(:focus) {
		&.ng-invalid {
			border-color: $invalid;
		}
	}
}

textarea {
	border: 1px solid $border-medium;
	background: transparent;
	color: $text-high-contrast;
	height: 10rem;
	display: flex;
	padding: 1rem;
	font-size: 15px;
	border-radius: 4px;
	outline: none;
	width: 100%;
	transition: all 0.2s ease-out;
	&:focus {
		border-color: $accent-1;
		box-shadow: 0 0 0 3px lighten($accent-1, 40);
	}

	&.ng-touched:not(:focus) {
		&.ng-invalid {
			border-color: $invalid;
		}
	}
}

.search-input {
	position: relative;
	height: 4.5rem;
	display: flex;
	width: 100%;
	align-items: center;

	i {
		position: absolute;
		left: 1.5rem;
		color: $text-low-contrast;
	}
	input {
		padding-left: 5rem;
	}
}

/**
 * Dialog
 **/
.dialog-title {
	font-size: 32px;
	color: $text-high-contrast;
	margin-bottom: 3rem;
	font-weight: 500;
}

.dialog-buttons {
	margin-top: 3rem;
	button {
		margin-right: 2rem;
	}
}
